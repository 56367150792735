import React, { useContext, useEffect, useState } from "react";
import { FetchContext } from "../../context/FetchContext";
import { useParams } from "react-router-dom";
import { message, Form } from "antd";

export const useFlatDetails = () => {
  const { authAxios, authAxios2 } = useContext(FetchContext);
  const [form] = Form.useForm();
  const [users, setUsers] = useState([]);
  const [payments, setPayments] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [comments, setComments] = useState([]);
  const [urlParams, setUrlParams] = useState(useParams);
  const [deviceId, setDeviceId] = useState(urlParams.id);
  const [id, setId] = useState(urlParams.number);
  const [flatNumber, setFlatNumber] = useState("");
  const [flatDetails, setFlatDetails] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [deleteUser, setDeleteUser] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [residents, setResidents] = useState("");
  const [balance, setBalance] = useState("");
  const [intercomCode, setIntercomCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [NFCs, setNFCs] = useState([]);
  const [addNFCModal, setAddNFCModal] = useState<boolean>(false);
  const [showNFCConfirm, setShowNFCConfirm] = useState(false);
  const [showAddKeys, setShowAddKeys] = useState(false);
  const [parkingInfo, setParkingInfo] = useState<any>(null);
  const [relatedIntercoms, setRelatedIntercoms] = useState(null);
  const [relatedFlats, setRelatedFlats] = useState([]);
  const [packagesFromIntercom, setPackagesFromIntercom] = useState<any[]>([]);
  const [selectedPackage, setSelectedPackage] = useState<any>(null);

  const getDetails = async () => {
    let address = sessionStorage.getItem('address');
    let addressParsed = JSON.parse(address);
    
    const data = await authAxios.get(`/admin/flats/${id}`);
    const flatDetails = {
      id: data.data.data.id,
      flatNumber: data.data.data.flatNumber,
      active: data.data.data.active,
      balance: data.data.data.balance,
      activePackage: data.data.data.activePackage,
      nextPackage: data.data.data.nextPackage,
      activeUntil: data.data.data.activeUntil,
      users: data.data.data.userCodes,
      payments: data.data.data.payments,
      transactions: data.data.data.transactions,
      comments: data.data.data.comments,
      residentCount: data.data.data.residentCount,
      address: data.data.data.address
    };
    setFlatDetails([flatDetails]);
    setResidents(data.data.data.residentCount);
    setUsers(data.data.data.userCodes);
    setPayments(data.data.data.payments.sort(function(a,b) {
      return new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime();
    }));
    setTransactions(data.data.data.transactions.sort(function(a,b) {
      return new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime();
    }));
    setFlatNumber(flatDetails.flatNumber);
    setComments(data.data.data.comments);

    const tempPackage = data.data.data.nextPackage || data.data.data.activePackage;
    getPackagesFromIntercom(tempPackage);

    return flatDetails;
  };

  const getParkingInfo = async () => { 
    let addressId = sessionStorage.getItem('address_id');

    if(addressId) {
      try {
        const response = await authAxios2.get(`/parking/get/${addressId}`);
        if(response) {
          //console.log('GET PARKING INFO ==>', response);
          setParkingInfo(response.data.parking);
        }
      } catch (e) {
        console.log('PARKING INFO ERROR ==>', e);  
      }
    }
  };
  
  useEffect(() => {
    getDetails();
    getRelatedIntercoms();
    getRelatedFlats();
  }, [authAxios, refresh]);

  useEffect(() => {
    getKeysForFlat();
    getParkingInfo();
  }, [authAxios2]);

  const getPackagesFromIntercom = async (activePackage: any) => {
    if (!deviceId) {
      return;
    }

    await authAxios
        .get(`admin/packages/intercoms/${deviceId}`)
        .then((response) => {
          if (response) {
            setPackagesFromIntercom(response.data.data);

            if (activePackage) {
              setSelectedPackage(response.data.data.find(pkg => pkg.id === activePackage.id) || null);
            }
          }
        });
  };

  const updatePackage = async (flatId: string, packageId: string) => {
    try {
      const response = await authAxios.put(`/admin/packages/flat`, {
        flatId: flatId,
        packageId: packageId
      });
      if(response) {
        console.log('`Update Package` ==>', response);
        setRefresh(true);
      }
    } catch (e) {
      console.log('Update Package ERROR ==>', e);
    }
  };

  const onPackageChange = async (pkgName: string) => {
    const selectedPkg = packagesFromIntercom.find(pkg => pkg.name === pkgName) || null;
    setSelectedPackage(selectedPkg);

    if(selectedPkg) {
      updatePackage(id, selectedPkg.id)
    }
  };

  const getRelatedIntercoms = async () => {
    try {
      const response = await authAxios.get(`/admin/intercoms/${deviceId}/relations`);
      if(response) {
        //console.log('GET INTERCOMS RESPONSE ==>', response.data.data);
        setRelatedIntercoms(response.data.data.relations);
      }
    } catch (e) {
      console.log('GET INTERCOMS ERROR ==>', e);    
    }
  };

  const getRelatedFlats = async () => {
    try {
      const response = await authAxios.get(`/admin/intercoms/${deviceId}/relatedFlats/${id}`);
      if(response) {
        console.log('Related Flats ==>', response);
        setRelatedFlats(response.data.data.relatedFlats);
      }
    } catch (e) {
      console.log('Related Flats ERROR ==>', e);
      
    }
  };

  const handleFlatActivationOnClick = (active: boolean) => {
    const updateActive = async () => {
      await authAxios
        .post(`/flats/active/${deviceId}`, {
          isActive: !active,
          flatNumber: flatNumber,
        })
        .then((response) => {
          if (response) {
            message.success("Flat has been successfully updated");
            const data = response.data.data.filter(
              (item) => flatNumber === item.flatNumber
            );
            setRefresh(!refresh);
          }
        })
        .catch((err) => {
          message.error(
            "There was a problem flat update. Please try again later!"
          );
        });
    };
    updateActive();
  };

  const addUserToParking = async (userId) => {
    try {
      const response = await authAxios2.post(`/parking/addtouser`, {
        user_id: userId,
        parking_id: parkingInfo.id
      });
      if(response) {
        console.log('User to Parking ==>', response);
        message.success("Parking has been successfully added to user");
        return true;    
      }
    } catch (e) {
      console.log('User to Parking error ==>', e);
      message.error("There was a problem with adding parking to user.");
      return false;
    }
  };

  const addUser = async () => {
    await authAxios
      .put(`/admin/intercoms/link`, {
        code: intercomCode,
        phoneNumber: phoneNumber,
      })
      .then(async (response) => {
        if (response) {
          message.success("User has successfully added");
          const details = await getDetails();
          if(details) {
            if(parkingInfo) {
              //console.log('Details ==>', details);
              for(let user of details?.users) {
                if(user.code === intercomCode) {
                    addUserToParking(user.user.id);
                }
              }
            }
          }
          setShowAddUserModal(false);
        }
      })
      .catch((err) => {
        message.error("There was a problem add user.");
        setShowAddUserModal(false);
      });
  };

  const editBalance = async () => {
    await authAxios
      .put(`admin/flats/${id}`, {
        flatBalance: +balance,
      })
      .then((response) => {
        if (response) {
          message.success("Balance has been successfully edited!");
          window.location.reload();
        }
      })
      .catch((err) => {
        message.error("There was a problem while editing balance!");
      });
  };

  const editResidents = async () => {
    try {
      const response = await authAxios.put(`/flats/${id}/resident`, {
        residentCount: Number(residents)
      });
      if(response) {
        message.success("Number of residents has been successfully edited!");
        setShowEditModal(false);
        window.location.reload();
      }
    } catch (e) {
      console.log('EDIT NUMBER OF RESIDENTS ERROR ==>', e);
      
    }
  };

  const handleUserActivationOnClick = (user: any) => {
    if (user == null) {
      message.error(
        "User details cannot be edited if the user has not signed up yet"
      );
    } else {
      const active = user.isActive === true ? "disable" : "enable";
      const updateActive = async () => {
        await authAxios
          .put(`/people/${active}`, {
            userId: user.id,
            intercomId: deviceId,
            flatNumber: flatNumber,
          })
          .then((response) => {
            if (response) {
              message.success("User has been successfully updated");
            }
          })
          .catch((err) => {
            message.error(
              "There was a problem with user update. Please try again later!"
            );
          });
      };
      updateActive();
    }
  };

  const handleEditBalanceModal = () => {
    setShowEditModal(true);
  };

  const handleEditResidentsModal = () => {
    setShowEditModal(true);
  };

  const handleAddUserModal = (code) => {
    setShowAddUserModal(true);
    setIntercomCode(code);
  };

  const handleCancelEdit = () => {
    setShowEditModal(false);
  };

  const handleCancelAddUser = () => {
    setShowAddUserModal(false);
  };

  const handleDeleteUserFromFlat = (user: any) => {
    if (user == null) {
      message.error(
        "User details cannot be edited if the user has not signed up yet"
      );
    } else {
      const updateActive = async () => {
        await authAxios
          .put(`/people/delete`, {
            userId: user.id,
            intercomId: deviceId,
            flatNumber: flatNumber,
          })
          .then((response) => {
            if (response) {
              message.success("User has been successfully updated");
            }
          })
          .catch((err) => {
            message.error(
              "There was a problem with user update. Please try again later!"
            );
          });
      };
      updateActive();
    }
  };

  const handleDeleteCommentFromFlat = async (commentId: any) => {
    try {
      const response = await authAxios.delete(`/admin/flats/${id}/comment/${commentId}`);
      if(response) {
        message.success("Comment has been successfully deleted");
        getDetails();
      }
    } catch (e) {
      console.log('DELETE COMMENT ERROR ==>', e);
       message.error(
        "There was a problem with comment removal. Please try again later!"
      );
    }
  };

  const showEditPackageModal = (value: any) => {};
  const handleCancelDelete = () => {
    setDeleteUser(false);
  };

  const addComment = async (value: any) => { 
    try {
      const response = await authAxios.put(`/admin/flats/${id}/comment`, {
        comment: value.body,
      });
      if(response) {
        //console.log('ADD COMMENT RESPONSE ==>', response);
        message.success("Comment has been successfully added.");
        form.resetFields();
        getDetails();
      }
    } catch (e) {
      //console.log('ADD COMMENT ERROR ==>', e);
      message.error(
        "There was a problem while adding the comment. Please try again later!"
      );
    }
  };

  const handleDeleteNFCCode = async (id) => {
    try {
      const response = await authAxios2.post(`/admin/keys/delete`, {
        keys: [id]
      });
      if(response) {
        //console.log('DELETE KEY ==>', response);
        getKeysForFlat();
        message.success(
          response?.data?.message != null
            ? response.data.message
            : "Key has been successfully deleted!"
        ); 
      }
    } catch (e) {
      console.log('DELETE KEY ERROR ==>', e);
      message.error(
        e.response.data.message != null
          ? e.response.data.message
          : "Keys could not be deleted!"
      );  
    }
  };

  const handleCancelNFCCodeDelete = () => {
    setShowNFCConfirm(false);
  };

  const handleNFCActivationOnClick = async (key) => {
    try {
      const response = await authAxios2.post(`/admin/keys/enable`, {
        enable: !key.enabled,
        keys: [key.id] 
      });
      if(response) {
        //console.log('Enable/disable RESPONSE ==>', response);
        getKeysForFlat();
        enableKeysForRelatedFlat(key);
        message.success(
          response?.data?.message != null
            ? response.data.message
            : `Key has been successfully ${key.enabled ? 'disabled' : 'enabled'}!`
        ); 
      }
    } catch (e) {
      console.log('Enable/disable key ERROR ==>', e);
      message.error(
        e.response.data.message != null
          ? e.response.data.message
          : `Keys could not be ${key.enabled ? 'disabled' : 'enabled'}!`
      ); 
    }
  };

  const getKeysForFlat = async () => {
    try {
      const response = await authAxios2.get(`/admin/keys/${id}`);
      if(response) {
        //console.log('GET KEYS FOR FLAT ==>', response);
        setNFCs(response.data);
      }
    } catch (e) {
      console.log('GET KEYS FOR FLAT ERROR ==>', e);
    }
  };

  const getKeysForRelatedFlat = async (flatId) => {
    try {
      const response = await authAxios2.get(`/admin/keys/${flatId}`);
      if(response) {
        //console.log('GET KEYS FOR FLAT ==>', response);
        return response.data;
      }
    } catch (e) {
      console.log('GET KEYS FOR FLAT ERROR ==>', e);
    }
  };

  const enableKeysForRelatedFlat = async (baseKey) => {
    for(let flat of relatedFlats) {
      const relatedKeys = await getKeysForRelatedFlat(flat.flatId);
      if(relatedKeys) {
        for(let key of relatedKeys) {
          if(key.key === baseKey.key) {
            try {
              const response = await authAxios2.post(`/admin/keys/enable`, {
                enable: !key.enabled,
                keys: [key.id] 
              });
              if(response) {
                //console.log('Enable/Disable Related Key ==>', response);
                message.success(
                  response?.data?.message != null
                    ? response.data.message
                    : `Related Key has been successfully ${key.enabled ? 'disabled' : 'enabled'}!`
                ); 
              }
            } catch (e) {
              console.log('Enable/Disable Related Key ERROR ==>', e);
              message.error(
                e.response.data.message != null
                  ? e.response.data.message
                  : `Keys could not be ${key.enabled ? 'disabled' : 'enabled'}!`
              ); 
            }
          }
        }
      }
    }
  };

  const handleAddKeysOnClick = (flat: any) => { 
    setShowAddKeys(true);
  };
  const handleAddKeysCancel = (flat: any) => {
    setShowAddKeys(false);
  };

  const addKeys = async (values: any) => {
    let data = [
      {
        flat_id: id,
        hex: values.hex,
        device_id: urlParams.id
      }
    ];

    if(relatedIntercoms) {
      for(let relation of relatedIntercoms.relatedIntercomIds) {
        for(let flat of relatedFlats) {
          if(relatedIntercoms.baseIntercom === urlParams.id) {
            if(flat.intercomId === relation) {
              data.push({
                flat_id: flat.flatId,
                hex: values.hex,
                device_id: relation
              });
            }
          } else {
            data.push({
              flat_id: flat.flatId,
              hex: values.hex,
              device_id: relatedIntercoms.baseIntercom
            });
          }
        }
      }
    }

    for(let item of data) {
      try {
        const response = await authAxios2.post(`/admin/key/create`, item);
        if(response) {
          //console.log('Add Key ==>', response);
          message.success(
            response.data.message != null
              ? response.data.message
              : "Key has been successfully added"
          );
        }
      } catch (err) {
        console.log('Add Key ERROR ==>', err);
        message.error(
          err.response.data.message != null
            ? err.response.data.message
            : "Key could not be added!"
        );
        
      }
    }

    setShowAddKeys(false);
    getKeysForFlat();
  };

  return {
    deviceId,
    flatNumber,
    flatDetails,
    users,
    payments,
    handleFlatActivationOnClick,
    showEditPackageModal,
    handleUserActivationOnClick,
    handleDeleteUserFromFlat,
    handleCancelDelete,
    deleteUser,
    showEditModal,
    handleEditBalanceModal,
    handleCancelEdit,
    setBalance,
    editBalance,
    setPhoneNumber,
    setIntercomCode,
    addUser,
    handleAddUserModal,
    showAddUserModal,
    handleCancelAddUser,
    intercomCode,
    addComment,
    comments,
    form,
    handleEditResidentsModal,
    setResidents,
    editResidents,
    residents,
    handleDeleteCommentFromFlat,
    handleDeleteNFCCode,
    handleCancelNFCCodeDelete,
    handleNFCActivationOnClick,
    NFCs,
    addKeys,
    handleAddKeysCancel,
    handleAddKeysOnClick,
    showAddKeys,
    transactions,
    packagesFromIntercom,
    selectedPackage,
    onPackageChange
  };
};
