import React, { useContext, useEffect, useState } from "react";
// import ReactExport from "react-data-export";

import {
  AutoComplete,
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  Radio,
  Select,
  Space,
  Table,
  Tag,
} from "antd";
import qs from "qs";

import { ALL_FLATS, ID, useEditIntercom } from "./useEditIntercom";
import {
  BulbTwoTone,
  DeleteTwoTone,
  EditTwoTone,
  ExclamationCircleOutlined,
  SendOutlined,
  UserOutlined,
  HomeOutlined
} from "@ant-design/icons";
import { ServiceTable } from "./components/ServiceTable";
import { IDTable } from "./components/IDTable";
import { AuthContext } from "../../context/AuthContext";
import { useHistory, useLocation, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { urbanAddresses } from "../../util/addresses";
import { SearchInput } from "../../components/SearchInput";
import '../../colors.css';

const { Option } = AutoComplete;

export const EditIntercom = () => {
  const { t } = useTranslation()
  const {
    device,
    flats,
    handleConfirm,
    handleCancel,
    generateFlatCodes,
    addKeys,
    handleAddKeysCancel,
    handleAddKeysOnClick,
    showAddKeys,
    handleDeviceEdit,
    handleOnSelect,
    handleCancelEdit,
    showModal,
    getAutocompleteData,
    addresses,
    isModalVisible,
    handleDetailsOnClick,
    handleDetailsOk,
    handleDetailsCancel,
    isDetailsModalVisible,
    selectedFlat,
    handleFlatDelete,
    handleFlatDeleteCancel,
    handleAddFlatsOnClick,
    showAddFlats,
    handleAddFlatsCancel,
    addFlats,
    handleChangeComType,
    selectedComType,
    exportData,
    paymentTypeChange,
    secondDoorChange,
    isEditIntercomLoading,
    paymentType,
    secondDoor,
    services,
    selectedService,
    setSelectedServices,
    onServiceChange,
    onDateChange,
    selectedDate,
    addServiceValues,
    serviceFlatValues,
    checkedIDs,
    changeSelectAll,
    areAllChecked,
    changeCheckedValue,
    setSelectedFlat,
    handleAddNotificationOnClick,
    handleAddNotificationCancel,
    showNotificationModal,
    addNotifications,
    isNotificationSending,
    isSortedByBalance,
    setIsSortedByBalance,
    flatsData,
    changeTotalAmount,
    handleCharge,
    handleRefund,
    getPaymentsFile,
    getCodesFile,
    handlePaymentInputEnter,
    paymentValue,
    setPaymentValue,
    fixedPriceModal,
    handleFixedPriceCancel,
    unitValueType,
    unitValueTypeChange,
    days,
    daysNumberChange,
    setFixedPriceModal,
    handleAddIntercomOnClick,
    showConnectIntercoms,
    handleAddIntercomsCancel,
    connectIntercoms,
    intercomsData,
    toggleIntercomConnect,
    deviceID,
    usersStatus,
    flatsStatus,
    parkingChange,
    parking,
    parkingInfo,
    pushNotificationModal,
    handlePushNotificationCancel,
    pushNotifications,
    vposAccounts,
    selectedAccount,
    onAccountChange,
    exportPaymentData,
    setFilteredValue,
    filteredValue,
    setIntercomsData,
    getIntercomsForConnect,
    debtInfo,
    selectedColor,
    onFlatColorChange,
    flatCharged,
    isLoading,
    editExportIdValue,
    packagesFromIntercom,
    updatePackage
  } = useEditIntercom();

  const { confirm } = Modal;
  const [form] = Form.useForm();
  const auth = useContext(AuthContext);

  const colors = [
    {title: "No Color", value: "", label: <div>No Color</div>},
    {title: "Red", value: "#FF0000", label: <div style={{width: "20px", height: "20px", borderRadius: "10px", backgroundColor: "#FF0000", margin: "5px 0"}}></div>}, 
    {title: "Green", value: "#008000", label: <div style={{width: "20px", height: "20px", borderRadius: "10px", backgroundColor: "#008000", margin: "5px 0"}}></div>}, 
    {title: "Orange", value: "#FFA500", label: <div style={{width: "20px", height: "20px", borderRadius: "10px", backgroundColor: "#FFA500", margin: "5px 0"}}></div>}
  ];

  const handleShowConfirm = () => {
    confirm({
      title: t("DeleteIntercomDevice"),
      icon: <ExclamationCircleOutlined />,
      content: t("DeleteIntercomConfirm"),
      cancelText: t("Cancel"),
      onOk() {
        handleConfirm();
      },
      onCancel() {
        handleCancel();
      },
    });
  };

  const handleDeleteFlatConfirm = (flat) => {
    confirm({
      title: "Delete Flat",
      icon: <ExclamationCircleOutlined />,
      content: "Are You sure You want to delete this flat?",
      onOk() {
        handleFlatDelete(flat);
      },
      onCancel() {
        handleFlatDeleteCancel();
      },
    });
  };

  const getService = () => {
    const data = localStorage.getItem('selectedService');
    if (data !== null) {
      setSelectedServices(JSON.parse(data));
    }
  };

  useEffect(() => {
    if (services.length) {
      getService();
    }
  }, [services]);

  // useEffect(() => {
  //   if (selectedService !== ALL_FLATS) {
  //     onServiceChange(selectedService);
  //   }
  // }, [selectedDate.date]);

  // const checkRefund = () => {
  //   serviceFlatValues[selectedService].filter((item) => {
  //     if (checkedIDs[0] === item.flatId) {
  //       setRefundAvailable(item.charged);
  //     }
  //   })
  // };

  // useEffect(() => {
  //   if (checkedIDs.length === 1 && selectedService !== ALL_FLATS) {
  //     checkRefund();
  //   }
  // }, [checkedIDs.length, selectedService]);

  const DataSet = [
    {
      columns: [
        {
          title: `Address: ${device[0]?.address.address}`,
          style: { font: { sz: "18", bold: true } },
          width: { wpx: 400 },
        },
        {
          title: "Flat Number",
          style: { font: { sz: "18", bold: true } },
          width: { wpx: 140 },
        }, // width in pixels
        {
          title: "Code",
          style: { font: { sz: "18", bold: true } },
          width: { wpx: 125 },
        }, // width in characters
      ],
      data: exportData[0]?.map((data) => [
        { value: '', style: { font: { sz: "14" } } },
        {
          value: data?.flatNumber,
          style: { font: { sz: "14" }, textAlign: "left" },
        },
        { value: data.code, style: { font: { sz: "14" } } },
      ])
    },
  ];

  const columns = [
    {
      title: t("DeviceName"),
      dataIndex: "name",
      key: "name",
      render: () => {
        return <div>{t("BoonIntercom")}</div>
      }
    },
    {
      title: t("SerialNumber"),
      dataIndex: "serialNumber",
      key: "serialNumber",
    },
    {
      title: t("Address"),
      key: "address",
      dataIndex: "address",
      render: (address) => {
        if (!address) {
          return "Not Activated";
        }
        return <div>{`${address.address}`}</div>;
      },
    },
    {
      title: t("PaymentType"),
      key: "paymentType",
      dataIndex: "paymentType",
      render: (type) => {
        if (type === "BY_FLAT") {
          return <Tag color="#87d068">{t("ByFlat")}</Tag>;
        }
        return <Tag color="#2db7f5">{t("ByIntercom")}</Tag>;
      },
    },
    {
      title: t("Online"),
      key: "isOnline",
      dataIndex: "isOnline",
      render: (isOnline) => (
        <BulbTwoTone
          twoToneColor={isOnline === "true" ? "green" : "red"}
          style={{ fontSize: "20px" }}
        />
      ),
    },
    {
      title: t("Active"),
      key: "isActivated",
      dataIndex: "isActivated",
      render: (isActivated) => (
        <BulbTwoTone
          twoToneColor={isActivated === "true" ? "green" : "red"}
          style={{ fontSize: "20px" }}
        />
      ),
    },
    {
      title: t("Action"),
      key: "action",
      dataIndex: "id",
      render: (id, row) =>
        (!auth.isSuperAdmin) ? null : (
          <Space size="middle">
            <Button onClick={showModal} style={{ fontSize: '12px' }}>{t("Edit")}</Button>
            <Button danger onClick={handleShowConfirm} style={{ fontSize: '12px' }}>
              {t("Delete")}
            </Button>
          </Space>
        ),
    },
  ];

  const malkhasyantsFlatColumns = [
    {
      dataIndex: "id",
      key: "checkbox",
      title: (
        <Checkbox onClick={() => changeSelectAll()} checked={areAllChecked} />
      ),
      render: (id, row) => {
        return (
          <Checkbox
            value={id}
            checked={checkedIDs.includes(id)}
            onClick={() => {
              changeCheckedValue(row, id);
              setSelectedFlat(id);
            }}
          />
        );
      },
    },
    {
      title: t("FlatNumber"),
      dataIndex: "flatNumber",
      key: "flatNumber",
    },
    {
      title: `${t("Admin")}`,
      dataIndex: "admin",
      key: "admin",
      render: (admin, row) => {
        if (row.userCodes && row.userCodes.length) {
          for (let user of row.userCodes) {
            if (user.user && user.user.isAdmin === true) {
              return <div>{`${user.user.name}`}</div>
            }
          }
        }

        return <div>{`NONE`}</div>;
      },
    },
    auth.authState.userInfo.id === "8321730e-4b1a-4637-90c9-cc1133c32160" ?
        {
          title: t("Package"),
          dataIndex: "activePackage",
          key: "activePackage",
          render: (activePackage, row) => {
            const tempPackage = row.nextPackage || activePackage;
            return <div>{`${tempPackage ? tempPackage.name : "NONE"}`}</div>;
          }
        } : {},
    urbanAddresses[deviceID.id] ?
      {
        title: `${t("NumberOfResidents")}`,
        dataIndex: "residentCount",
        key: "residentCount",
        render: (residentCount) => {
          return (
            <div
            //onDoubleClick={() => handleEditResidentsModal()}
            >
              {`${residentCount}`}
            </div>
          );
        },
      } : {},
    {
      title: t("Users"),
      key: "userCodes",
      dataIndex: "userCodes",
      render: (userCodes) => {
        return <div>{`${userCodes.length}`}</div>;
      },
    },
    {
      title: t("Balance"),
      key: "balance",
      dataIndex: "balance",
      render: (balance) => {
        return <div>{`${+balance}`}</div>;
      },
    },
    {
      title: t("Color"),
      key: "color",
      dataIndex: "color",
      render: (color, row) => {
        return (
          <Select
            style={{ width: 100, margin: "0 8px 10px 0" }}
            placeholder="Select"
            value={!color ? colors[0] : color}
            onChange={(value) => onFlatColorChange(value, row.flatNumber)}
            options={colors}
            dropdownMatchSelectWidth={false}
          />
        );
      },
      filters: [
        {
          text: 'Red',
          value: '#FF0000',
        },
        {
          text: 'Green',
          value: '#008000',
        },
        {
          text: 'Orange',
          value: '#FFA500',
        },
      ],
      onFilter: (value: string, record) => { 
        if(record.color) {
          return record.color.indexOf(value) === 0
        }
      },
    },
    {
      title: t("Action"),
      key: "flatDetails",
      dataIndex: "id",
      render: (id, row) => {
        return (
          <Space size="middle">
            <EditTwoTone onClick={() => handleDetailsOnClick(row)} />
            <DeleteTwoTone
              twoToneColor="red"
              onClick={() => handleDeleteFlatConfirm(row)}
            />
          </Space>
        );
      },
    },
  ];

  const flatColumns = [
    {
      dataIndex: "id",
      key: "checkbox",
      title: (
        <Checkbox onClick={() => changeSelectAll()} checked={areAllChecked} />
      ),
      render: (id, row) => {
        return (
          <Checkbox
            value={id}
            checked={checkedIDs.includes(id)}
            onClick={() => {
              changeCheckedValue(row, id);
              setSelectedFlat(id);
            }}
          />
        );
      },
    },
    {
      title: t("FlatNumber"),
      dataIndex: "flatNumber",
      key: "flatNumber",
    },
    {
      title: `${t("Admin")}`,
      dataIndex: "admin",
      key: "admin",
      render: (admin, row) => {
        if (row.userCodes && row.userCodes.length) {
          for (let user of row.userCodes) {
            if (user.user && user.user.isAdmin === true) {
              return <div>{`${user.user.name} ${user.user.surname ? user.user.surname : ""}`}</div>
            }
          }
        }

        return <div>{`NONE`}</div>;
      },
    },
    {
      title: t("Package"),
      dataIndex: "activePackage",
      key: "activePackage",
      render: (activePackage) => {
        if (!activePackage) {
          return "NONE";
        }
        return <div>{`${activePackage.name}`}</div>;
      },
    },
    {
      title: t("ActiveUntil"),
      key: "activeUntil",
      dataIndex: "activeUntil",
      render: (activeUntil) => {
        if (!activeUntil) {
          return "NONE";
        }
        return <div>{`${activeUntil}`}</div>;
      },
    },
    {
      title: t("Users"),
      key: "userCodes",
      dataIndex: "userCodes",
      render: (userCodes) => {
        return <div>{`${userCodes.length}`}</div>;
      },
    },
    {
      title: t("Balance"),
      key: "balance",
      dataIndex: "balance",
      render: (balance) => {
        return <div>{`${+balance}`}</div>;
      },
    },
    {
      title: t("Color"),
      key: "color",
      dataIndex: "color",
      render: (color, row) => {
        return (
          <Select
            style={{ width: 100, margin: "0 8px 10px 0" }}
            placeholder="Select"
            value={!color ? colors[0] : color}
            onChange={(value) => onFlatColorChange(value, row.flatNumber)}
            options={colors}
            dropdownMatchSelectWidth={false}
          />
        );
      },
      filters: [
        {
          text: 'Red',
          value: '#FF0000',
        },
        {
          text: 'Green',
          value: '#008000',
        },
        {
          text: 'Orange',
          value: '#FFA500',
        },
      ],
      onFilter: (value: string, record) => { 
        if(record.color) {
          return record.color.indexOf(value) === 0
        }
      },
    },
    {
      title: t("Action"),
      key: "flatDetails",
      dataIndex: "id",
      render: (id, row) => {
        return (
          <Space size="middle">
            <EditTwoTone onClick={() => handleDetailsOnClick(row)} />
            <DeleteTwoTone
              twoToneColor="red"
              onClick={() => handleDeleteFlatConfirm(row)}
            />
          </Space>
        );
      },
    },
  ];

  const flatColumnsSuperAdmin = [
    {
      dataIndex: "id",
      key: "checkbox",
      title: (
          <Checkbox onClick={() => changeSelectAll()} checked={areAllChecked} />
      ),
      render: (id, row) => {
        return (
            <Checkbox
                value={id}
                checked={checkedIDs.includes(id)}
                onClick={() => {
                  changeCheckedValue(row, id);
                  setSelectedFlat(id);
                }}
            />
        );
      },
    },
    {
      title: t("FlatNumber"),
      dataIndex: "flatNumber",
      key: "flatNumber",
    },
    {
      title: `${t("Admin")}`,
      dataIndex: "admin",
      key: "admin",
      render: (admin, row) => {
        if (row.userCodes && row.userCodes.length) {
          for (let user of row.userCodes) {
            if (user.user && user.user.isAdmin === true) {
              return <div>{`${user.user.name} ${user.user.surname ? user.user.surname : ""}`}</div>
            }
          }
        }

        return <div>{`NONE`}</div>;
      },
    },
    {
      title: t("Package"),
      dataIndex: "activePackage",
      key: "activePackage",
      render: (activePackage, row) => {
        const tempPackage = row.nextPackage || activePackage;
        return <div>
          <Select
              style={{width: 120, marginRight: 16}}
              value={tempPackage ? tempPackage.name : "NONE"}
              onChange={(item) => updatePackage(row.id, item) }
              options={tempPackage === null
                  ? [{title: "NONE", value: "NONE"}, ...packagesFromIntercom.map(pkg => ({title: pkg.name, value: pkg.name}))]
                  : [...packagesFromIntercom.map(pkg => ({title: pkg.name, value: pkg.name}))]}
              dropdownMatchSelectWidth={false}
          />
        </div>
      }
    },
    {
      title: t("ActiveUntil"),
      key: "activeUntil",
      dataIndex: "activeUntil",
      render: (activeUntil) => {
        if (!activeUntil) {
          return "NONE";
        }
        return <div>{`${activeUntil}`}</div>;
      },
    },
    {
      title: t("Users"),
      key: "userCodes",
      dataIndex: "userCodes",
      render: (userCodes) => {
        return <div>{`${userCodes.length}`}</div>;
      },
    },
    {
      title: t("Balance"),
      key: "balance",
      dataIndex: "balance",
      render: (balance) => {
        return <div>{`${+balance}`}</div>;
      },
    },
    {
      title: t("Color"),
      key: "color",
      dataIndex: "color",
      render: (color, row) => {
        return (
            <Select
                style={{ width: 100, margin: "0 8px 10px 0" }}
                placeholder="Select"
                value={!color ? colors[0] : color}
                onChange={(value) => onFlatColorChange(value, row.flatNumber)}
                options={colors}
                dropdownMatchSelectWidth={false}
            />
        );
      },
      filters: [
        {
          text: 'Red',
          value: '#FF0000',
        },
        {
          text: 'Green',
          value: '#008000',
        },
        {
          text: 'Orange',
          value: '#FFA500',
        },
      ],
      onFilter: (value: string, record) => {
        if(record.color) {
          return record.color.indexOf(value) === 0
        }
      },
    },
    {
      title: t("Action"),
      key: "flatDetails",
      dataIndex: "id",
      render: (id, row) => {
        return (
            <Space size="middle">
              <EditTwoTone onClick={() => handleDetailsOnClick(row)} />
              <DeleteTwoTone
                  twoToneColor="red"
                  onClick={() => handleDeleteFlatConfirm(row)}
              />
            </Space>
        );
      },
    },
  ];

  const selectedFlatColumns = [
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "User",
      key: "name",
      dataIndex: "user",
      render: (user) => {
        if (!user) {
          return "NONE";
        }
        return <div>{`${user.name}`}</div>;
      },
    },
    {
      title: "Phone Number",
      key: "phone",
      dataIndex: "user",
      render: (user) => {
        if (!user) {
          return "NONE";
        }
        return <div>{`${user.phoneNumber}`}</div>;
      },
    },
  ];

  const intercomsColumns = [
    {
      dataIndex: "id",
      key: "checkbox",
      title: (
        <Checkbox onClick={() => changeSelectAll()} checked={areAllChecked} />
      ),
      render: (id, row) => {
        return (
          <Checkbox
            value={id}
            checked={checkedIDs.includes(id)}
            onClick={() => {
              toggleIntercomConnect(row, id);
            }}
          />
        );
      },
    },
    {
      title: t("ID"),
      dataIndex: "id",
      key: "id",
    },
    {
      title: t("Address"),
      key: "address",
      dataIndex: "address",
      render: (address) => {
        if (!address) {
          return "Not Activated";
        }
        return <div>{`${address.address ? address.address : address}`}</div>;
      }
    }
  ];


  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const history = useHistory();
  const location = useLocation();
  const handleTableChange = (pagination: any, filters: any) => {
    console.log('params', pagination, filters);
    setLimit(pagination.pageSize);
    history.push(`?page=${pagination.current}&limit=${pagination.pageSize}`); // update the URL
  };
  useEffect(() => {
    // Read the current page number from the URL query parameters
    const query = qs.parse(location.search, { ignoreQueryPrefix: true });
    const page = typeof query.page === 'string' ? parseInt(query.page as string) : 1;
    const limit = typeof query.limit === 'string' ? parseInt(query.limit as string) : 10;
    setCurrentPage(page);
    setLimit(limit);
  }, [location.search]);

  return (
    <div>
      {/* <Button onClick={handleAddKeysOnClick} style={{ marginRight: 8 }}>
        {" "}{t("AddMoreKeys")}{" "}
      </Button> */}
      <Button
        style={{ marginRight: 8 }}
      >
        <Link to={{ pathname: `${deviceID.id}/keys`, state: { address: device[0]?.address } }}>{" "}{t("Keys")}{" "}</Link>
      </Button>
      <Button onClick={handleAddFlatsOnClick} style={{ marginRight: 8 }}>
        {" "}{t("AddFlat")}{" "}
      </Button>
      <Button onClick={getCodesFile} style={{ marginRight: 8 }} disabled={isLoading}>
        {" "}{t("Export6DigitCodes")}{" "}
      </Button>
      <Button onClick={getPaymentsFile} style={{ marginRight: 8, marginBottom: 16 }} disabled={isLoading}>
        {" "}{t("ExportStatement")}{" "}
      </Button>
      <Button onClick={handleAddIntercomOnClick} style={{ marginRight: 8 }}>
        {" "}{t("ConnectIntercom")}{" "}
      </Button>
      <Table columns={columns} dataSource={device} pagination={false} />
      <Divider orientation={"left"}>Flats </Divider>
      <Select
        style={{ width: 120, marginRight: 16 }}
        value={selectedService}
        onChange={(service) => onServiceChange(service, selectedDate)}
        options={[{ title: ALL_FLATS, value: ALL_FLATS }, ...services, {title: ID, value: ID}]}
        dropdownMatchSelectWidth={false}
      />
      <DatePicker
        onChange={onDateChange}
        picker="month"
        disabled={selectedService === ALL_FLATS}
        value={selectedDate.date}
      />
      <Button
        onClick={() => handleAddNotificationOnClick((selectedService !== ALL_FLATS && selectedService !== ID) ? 'push' : 'send')}
        disabled={checkedIDs.length === 0}
        icon={<SendOutlined />}
        style={{ marginLeft: 8 }}
      >
        { (selectedService !== ALL_FLATS && selectedService !== ID) ? t("PushNotification") : t("SendNotification")}
      </Button>
      <Button
        onClick={() => setIsSortedByBalance(!isSortedByBalance)}
        style={{ marginLeft: 8 }}
      >
        {isSortedByBalance ? t("SortWithFlatNumbers") : t("SortWithBalance")}
      </Button>
      <Input
        type="text"
        onPressEnter={handlePaymentInputEnter}
        placeholder={t('Payments')}
        style={{ marginLeft: 8, width: '10%' }}
        disabled={!checkedIDs.length}
        value={paymentValue}
        onChange={(e) => {
          const re = /^-?\d*\.?\d{0,6}$/;
          if (e.target.value === '' || re.test(e.target.value)) {
            setPaymentValue(e.target.value);
          }
        }}
      />
      <Button
        style={{ marginLeft: 6, backgroundColor: 'white' }}
      >
        <HomeOutlined style={{ color: "#87d068" }} />
        <span style={{ marginLeft: 6, color: "#87d068" }}>{flatsStatus[`activeFlats`]}</span>
      </Button>
      <Button
        style={{ marginLeft: 6, backgroundColor: 'white' }}
      >
        <UserOutlined style={{ color: "#87d068" }} />
        <span style={{ marginLeft: 6, color: "#87d068" }}>{usersStatus[`activeUsers`]}</span>
      </Button>
      <Button
        style={{ marginLeft: 6, backgroundColor: 'white' }}
      >
        <HomeOutlined style={{ color: "red" }} />
        <span style={{ marginLeft: 6, color: "red" }}>{flatsStatus[`disabledFlats`]}</span>
      </Button>
      <Button
        style={{ marginLeft: 6, backgroundColor: 'white' }}
      >
        <UserOutlined style={{ color: "red" }} />
        <span style={{ marginLeft: 6, color: "red" }}>{usersStatus[`disabledUsers`]}</span>
      </Button>
      {selectedService !== ALL_FLATS ? (
        <>
          <Button
            onClick={() => handleCharge(checkedIDs)}
            disabled={checkedIDs.length === 0 || (checkedIDs.length === 1 && flatCharged[checkedIDs[0]] === true)}
            style={{ marginLeft: 8 }}>
            {'Charge'}
          </Button>
          {/* <Button
            onClick={() => handleRefund(selectedFlat)}
            disabled={!refundAvailable || checkedIDs.length === 0}
            style={{ marginLeft: 6 }}>
            {'Refund'}
          </Button> */}
        </>
      ) : null}
      <Divider orientation={"left"} />
      {selectedService === ALL_FLATS ? (
        <Table
          columns={!auth.isSuperAdmin ? malkhasyantsFlatColumns : flatColumnsSuperAdmin}
          dataSource={flatsData}
          onChange={handleTableChange}
          rowClassName={(record, index) => record.color === "#FF0000" ? "red" : record.color === "#008000" ? "green" : record.color === "#FFA500" ? "orange" : "" }
          pagination={{ current: currentPage, pageSize: limit, pageSizeOptions: ["10", "20", "50", "100"], showSizeChanger: true }}
        />
      ) : selectedService === ID ?
        <IDTable
          flats={flats}
          selectedService={selectedService}
          serviceFlatValues={serviceFlatValues}
          services={services}
          editTotal={changeTotalAmount}
          handleTableChange={handleTableChange}
          currentPage={currentPage}
          limit={limit}
          areAllChecked={areAllChecked}
          checkedIDs={checkedIDs}
          changeSelectAll={changeSelectAll}
          changeCheckedValue={changeCheckedValue}
          setSelectedFlat={setSelectedFlat}
          editExportIdValue={editExportIdValue}
        />
      : (
        <ServiceTable
          flats={flats}
          addServiceValues={addServiceValues}
          selectedService={selectedService}
          serviceFlatValues={serviceFlatValues}
          services={services}
          editTotal={changeTotalAmount}
          handleTableChange={handleTableChange}
          currentPage={currentPage}
          limit={limit}
          areAllChecked={areAllChecked}
          checkedIDs={checkedIDs}
          changeSelectAll={changeSelectAll}
          changeCheckedValue={changeCheckedValue}
          setSelectedFlat={setSelectedFlat}
          fixedPriceModal={fixedPriceModal}
          handleFixedPriceCancel={handleFixedPriceCancel}
          unitValueType={unitValueType}
          unitValueTypeChange={unitValueTypeChange}
          setFixedPriceModal={setFixedPriceModal}
          currentMonth={selectedDate}
          handleRefund={handleRefund}
          days={days}
          daysNumberChange={daysNumberChange}
        />
      )}
      <Modal
        title={t("EditDevice")}
        open={isModalVisible}
        onOk={form.submit}
        onCancel={handleCancelEdit}
        cancelText={t("Cancel")}
        style={{ pointerEvents: "none" }}
        okButtonProps={{ disabled: isEditIntercomLoading }}
        cancelButtonProps={{ disabled: isEditIntercomLoading }}
      >
        <Form
          form={form}
          disabled={isEditIntercomLoading}
          onFinish={handleDeviceEdit}
          initialValues={{
            address: device[0]?.address.address,
            lock_name: device[0]?.lockName,
            paymentType,
            secondDoor,
            second_lock_name: device[0]?.secondDoorName,
            vposAccount: device[0]?.vposAccountId,
            parking,
            parking_name: parkingInfo?.name,
            condominium_debt: debtInfo.fixAmount && debtInfo.fixAmount > 0 ? debtInfo.fixAmount : '-',
            repayment_amount: debtInfo.minPayment && debtInfo.minPayment > 0 ? debtInfo.minPayment : '-'
          }}
        >
          <p>{t("Name")}: {device[0]?.name}</p>
          <p>{t("Address")}: {device[0]?.address.address}</p>
          <Form.Item name="address">
            <AutoComplete
              style={{ width: 200 }}
              onSearch={getAutocompleteData}
              placeholder={t("DeviceAddress")}
              onSelect={handleOnSelect}
            >
              {addresses.map((data: any) => (
                <Option key={data.description} value={data.description}>
                  {data.description} {data.structured_formatting.secondary_text}
                </Option>
              ))}
            </AutoComplete>
          </Form.Item>
          <Form.Item label={t("LockName")} name="lock_name">
            <Input type={"text"} placeholder={t("EntranceDoor")} />
          </Form.Item>
          <Divider />
          <Form.Item label={t("Commutator")} name="ext_com">
            <Select
              defaultValue={`${selectedComType}`}
              style={{ width: 200 }}
              onChange={handleChangeComType}
            >
              <Option value={0}>Метаком</Option>
              <Option value={1}>Визит</Option>
              <Option value={2}>Цифрал</Option>
              <Option value={3}>Элтис</Option>
            </Select>
          </Form.Item>
          <Form.Item label={t("PaymentType")} name="paymentType">
            <Radio.Group onChange={paymentTypeChange} value={paymentType}>
              <Space direction="horizontal">
                <Radio value={"BY_INTERCOM"}>{t("ByIntercom")}</Radio>
                <Radio value={"BY_FLAT"}>{t("ByFlat")}</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          <Form.Item label={t("SecondDoor")} name="secondDoor">
            <Radio.Group onChange={secondDoorChange} value={secondDoor}>
              <Space direction="horizontal">
                <Radio value={true}>{t("Yes")}</Radio>
                <Radio value={false}>{t("No")}</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          {secondDoor && (
            <Form.Item label={t("SecondDoorName")} name="second_lock_name">
              <Input type={"text"} placeholder={t("EntranceDoor")} />
            </Form.Item>
          )}
          <Form.Item
            label={t("vposAccount")}
            name="vposAccount"
          >
            <Select
              style={{ width: 200, marginRight: 16 }}
              value={selectedAccount}
              onChange={onAccountChange}
              placeholder={t('chooseOption')}
              options={vposAccounts}
            />
          </Form.Item>
          <Form.Item label={t("Parking")} name="parking">
            <Radio.Group onChange={parkingChange} value={parking}>
              <Space direction="horizontal">
                <Radio value={true}>{t("Yes")}</Radio>
                <Radio value={false}>{t("No")}</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          {parking && (
            <Form.Item label={t("ParkingName")} name="parking_name">
              <Input type={"text"} placeholder={t("Parking")} />
            </Form.Item>
          )}
          <Form.Item label={t("CondominiumDebt")} name="condominium_debt">
            <Input type={"text"} placeholder={t("EnterAmount")} />
          </Form.Item>
          <Form.Item label={t("RepaymentAmount")} name="repayment_amount">
            <Input type={"text"} placeholder={t("EnterAmount")} />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title={t("AddNotification")}
        open={showNotificationModal}
        onOk={form.submit}
        onCancel={handleAddNotificationCancel}
        style={{ pointerEvents: "none" }}
        footer={null}
      >
        <Form form={form} onFinish={addNotifications}>
          <Form.Item
            label={t("Title")}
            name="title"
            //rules={[{ required: true, message: "Please input the title!" }]}
            preserve
          >
            <Input type={"text"} />
          </Form.Item>
          <Form.Item
            label={t("Message")}
            name="body"
            //rules={[{ required: true, message: "Please input the message!" }]}
            preserve
          >
            <Input.TextArea />
          </Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={isNotificationSending}
            icon={<SendOutlined />}
          >
            {t("Send")}
          </Button>
        </Form>
      </Modal>

      <Modal
        title={t("PushPopupTitle")}
        open={pushNotificationModal}
        onOk={form.submit}
        okText={t('Ok')}
        onCancel={handlePushNotificationCancel}
        cancelText={t("Cancel")}
        style={{ pointerEvents: "none" }}
        width={600}
      >
        <Form form={form} onFinish={pushNotifications}>
          <span>
            {t("PushPopupText")}
          </span>
        </Form>
      </Modal>

      <Modal
        title={t("AddKeys")}
        open={showAddKeys}
        onOk={form.submit}
        onCancel={handleAddKeysCancel}
        style={{ pointerEvents: "none" }}
        footer={null}
      >
        <Form form={form} onFinish={addKeys}>
          <Form.Item
            label={t("AddKey")}
            name="hex"
            //rules={[{ required: true, message: "Please input hex code!" }]}
            preserve
          >
            <Input type={"text"} />
          </Form.Item>
          <Button type="primary" htmlType="submit">
            {t("Save")}
          </Button>
        </Form>
      </Modal>

      <Modal
        title={t("AddFlat")}
        open={showAddFlats}
        onOk={form.submit}
        onCancel={handleAddFlatsCancel}
        style={{ pointerEvents: "none" }}
        footer={null}
      >
        <Form form={form} onFinish={addFlats}>
          <Form.Item
            label={t("FlatNumber")}
            name="num"
            //rules={[{ required: true, message: "Please add flat Number!" }]}
            preserve
          >
            <Input type={"text"} />
          </Form.Item>
          <Button type="primary" htmlType="submit">
            {t("Save")}
          </Button>
        </Form>
      </Modal>
      <Modal
        title="Flat Details"
        open={isDetailsModalVisible}
        onOk={handleDetailsOk}
        onCancel={handleDetailsCancel}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <p>Flat Number: {selectedFlat["flatNumber"]} </p>
        <p>
          <Button onClick={generateFlatCodes}>Generate Flat Code</Button>
        </p>
        <Table
          columns={selectedFlatColumns}
          dataSource={selectedFlat["userCodes"]}
        />
      </Modal>
      <Modal
        title={t("ConnectIntercom")}
        open={showConnectIntercoms}
        onOk={form.submit}
        onCancel={handleAddIntercomsCancel}
        style={{ pointerEvents: "none" }}
        footer={null}
      >
        <Form form={form} onFinish={() => connectIntercoms(checkedIDs)}>
          <SearchInput
            setFilterValue={setFilteredValue}
            setIntercomsData={setIntercomsData}
            getIntercomsForConnect={getIntercomsForConnect}
            placeHolder={t("SearchForAddress")}
          />
          <Table
            columns={intercomsColumns}
            dataSource={intercomsData}
            pagination={false}
          />
          <Button type="primary" htmlType="submit" style={{ marginTop: "1em" }}>
            {t("Save")}
          </Button>
        </Form>
      </Modal>
    </div>
  );
};
