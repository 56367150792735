import { useEffect, useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import {
  DesktopOutlined,
  PictureOutlined,
  AlertOutlined,
  InboxOutlined,
  ThunderboltOutlined,
  KeyOutlined
} from "@ant-design/icons";
export const navItems = [
  {
    label: "Intercoms",
    path: "/intercoms",
    icon: AlertOutlined,
    allowedRoles: ["user", "admin"],
  },
  {
    label: "Elevators",
    path: "/elevators",
    icon: KeyOutlined,
    allowedRoles: ["user", "admin"],
  },
  {
    label: "Services",
    path: "/services",
    icon: ThunderboltOutlined,
    allowedRoles: ["admin"],
  },
  {
    label: "Devices",
    path: "/devices",
    icon: DesktopOutlined,
    allowedRoles: ["user", "admin"],
  },
  {
    label: "Slides",
    path: "/slides",
    icon: PictureOutlined,
    allowedRoles: ["user", "admin"],
  },
  {
    label: "Packages",
    path: "/packages",
    icon: InboxOutlined,
    allowedRoles: ["user", "admin"],
  },
];

export const malkhasyantsNavItems = [
  {
    label: "Intercoms",
    path: "/intercoms",
    icon: AlertOutlined,
    allowedRoles: ["user", "admin"],
  },
  {
    label: "Services",
    path: "/services",
    icon: ThunderboltOutlined,
    allowedRoles: ["admin"],
  },
  {
    label: "Slides",
    path: "/slides",
    icon: PictureOutlined,
    allowedRoles: ["user", "admin"],
  },
];

export const navItemsLimited = [
  {
    label: "Intercoms",
    path: "/intercoms",
    icon: AlertOutlined,
    allowedRoles: ["user", "admin"],
  },
  {
    label: "Elevators",
    path: "/elevators",
    icon: KeyOutlined,
    allowedRoles: ["user", "admin"],
  },
  {
    label: "Services",
    path: "/services",
    icon: ThunderboltOutlined,
    allowedRoles: ["admin"],
  },
  {
    label: "Devices",
    path: "/devices",
    icon: DesktopOutlined,
    allowedRoles: ["user", "admin"],
  },
  {
    label: "Slides",
    path: "/slides",
    icon: PictureOutlined,
    allowedRoles: ["user", "admin"],
  },
];

export const useAppShell = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [defaultSelected, setDefaultSelected] = useState("0");
  const auth = useContext(AuthContext);

  const location = useLocation();

  const onCollapse = (collapsed: boolean) => {
    setCollapsed(collapsed);
  };
  useEffect(() => {
    const isCurrentRoute = location.pathname;
    const activeItem = (auth.isSuperAdmin ? navItems : (auth.authState.userInfo.id === "53714479-21b0-47c0-9b7d-101001e94ac9" || auth.authState.userInfo.id === "a3361db0-3b59-4020-8192-ba3c2052917a" || auth.authState.userInfo.id === "ffca3360-b8d4-4d88-bb97-948d839293e4" || auth.authState.userInfo.id === "09a71f42-33a6-4326-a91a-6f495e3d8b97" || auth.authState.userInfo.id === "1a9d1089-c9dc-434f-abad-b0f0a2295e5b" || auth.authState.userInfo.id === "f711010e-7028-485e-b115-4b9b174d2ff4"  || auth.authState.userInfo.id === "72a06bc9-68b8-4bad-956b-803bb0aef706") ? navItemsLimited : malkhasyantsNavItems).findIndex((el) =>
      isCurrentRoute.startsWith(`${el.path}`)
    );

    setDefaultSelected(activeItem + "");
  }, [location.pathname]);

  return {
    collapsed,
    onCollapse,
    defaultSelected,
  };
};
