import React, { useContext, useEffect, useState } from "react";

import {
  AutoComplete,
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  Modal,
  Space,
  Table,
  Switch,
  Tag,
  Select
} from "antd";
import qs from "qs";

import { ALL_FLATS, useEditElevator } from "./useEditElevator";
import {
  BulbTwoTone,
  DeleteTwoTone,
  EditTwoTone,
  ExclamationCircleOutlined,
  SendOutlined,
} from "@ant-design/icons";
import { ServiceTable } from "./components/ServiceTable";
import { AuthContext } from "../../context/AuthContext";
import { useHistory, useLocation, Link } from "react-router-dom";
import {useTranslation} from "react-i18next";
import { format } from "date-fns";
import { TableSearchInput } from "../../components/TableSearchInput";
import '../../colors.css';

const { Option } = AutoComplete;

export const EditElevator = (props) => {
    const {t} = useTranslation()
    const {
        elevatorDetails,
        flats,
        flatsUser,
        showModal,
        isModalVisible,
        handleCancelEdit,
        isEditElevatorLoading,
        handleElevatorEdit,
        handleConfirm,
        handleCancel,
        handleAddFlatOnClick,
        handleAddNFCsOnClick,
        showAddFlats,
        handleAddFlatCancel,
        addFlat,
        handleNFCActivationOnClick,
        active,
        NFCUploadModal,
        handleAddNFCsCancel,
        uploadNFCFile,
        handleOnChange,
        handleAddNFCForFlatOnClick,
        addNFCModal,
        handleAddNFCModalCancel,
        handleAddNFCForFlat,
        checkedIDs,
        setSelectedFlat,
        changeCheckedValue,
        areAllChecked,
        changeSelectAll,
        handleDetailsOnClick,
        handleAddNotificationOnClick,
        setIsSortedByBalance,
        isSortedByBalance,
        showNotificationModal,
        handleAddNotificationCancel,
        addNotifications,
        isNotificationSending,
        handleFlatConfirm,
        handleAddUserCodeModal,
        showAddUserCodeModal,
        setShowAddUserCodeModal,
        handleCancelAddUserCode,
        addUserCode,
        getFlats,
        handleForceOpen,
        forceOpenModal,
        handleOpenCancel,
        handleOpenOnClick,
        elevatorId,
        isLoading,
        pinCode,
        pinCodes,
        guestPins,
        selectedGuestPinCodes,
        onPinCodeChange,
        handleDeletePinCodes,
        selectedPinCodes,
        onFlatColorChange,
        handleAddElevatorOnClick,
        showConnectElevators,
        handleAddElevatorsCancel,
        elevatorsData, 
        setElevatorsData,
        getElevatorssForConnect,
        filteredValue,
        setFilteredValue,
        connectElevators,
        toggleElevatorConnect
    } = useEditElevator();

    const {confirm} = Modal;
    const [form] = Form.useForm();
    const auth = useContext(AuthContext);

    const colors = [
      {title: "No Color", value: "", label: <div>No Color</div>},
      {title: "Red", value: "#FF0000", label: <div style={{width: "20px", height: "20px", borderRadius: "10px", backgroundColor: "#FF0000", margin: "5px 0"}}></div>}, 
      {title: "Green", value: "#008000", label: <div style={{width: "20px", height: "20px", borderRadius: "10px", backgroundColor: "#008000", margin: "5px 0"}}></div>}, 
      {title: "Orange", value: "#FFA500", label: <div style={{width: "20px", height: "20px", borderRadius: "10px", backgroundColor: "#FFA500", margin: "5px 0"}}></div>}
    ];

    const handleCloseForm = (editData) => {
        handleElevatorEdit(editData).then(r => {
                form.setFieldsValue({permanent_code: ''});
            }
        );
    };

    const handleShowConfirm = () => {
        confirm({
            title: t("DeleteElevatorDevice"),
            icon: <ExclamationCircleOutlined/>,
            content: t("DeleteElevatorConfirm"),
            cancelText: t("Cancel"),
            onOk() {
                handleConfirm();
            },
            onCancel() {
                handleCancel();
            },
        });
    };

    const handleDeleteFlatConfirm = (id) => {
        confirm({
            title: t("DeleteFlat"),
            icon: <ExclamationCircleOutlined/>,
            content: t("DeleteFlatConfirm"),
            cancelText: t("Cancel"),
            onOk() {
                handleFlatConfirm(id);
            },
            onCancel() {
                handleCancel();
            },
        });
    };

  const columns = [
    {
      title: t("ElevatorName"),
      dataIndex: "name",
      key: "name",
      render: (name) => {
        if (!name) {
          return "NONE";
        }
        return <div>{`${name}`}</div>;
      },
    },
    {
      title: t("SerialNumber"),
      dataIndex: "serial_code",
      key: "serial_code",
    },
    {
      title: t("Address"),
      key: "address",
      dataIndex: "address",
      render: (address, row) => {
        if (!address) {
          return "Not Activated";
        }     
        return <div>{`${address.address}${row.entrance_number ? `, Entrance ${row.entrance_number}` : ''}`}</div>;
      },
      sorter: (a, b) => {
        return a.address.address.localeCompare(b.address.address);
      },
    },
    {
      title: t("PaymentType"),
      key: "paymentType",
      dataIndex: "paymentType",
      render: (type) => {
        if (type !== "BY_FLAT") {
          return <Tag color="#87d068">{t("ByFlat")}</Tag>;
        }
        return <Tag color="#2db7f5">{t("ByIntercom")}</Tag>;
      },
    },
    {
      title: t("Online"),
      key: "isOnline",
      dataIndex: "isOnline",
      render: (isOnline) => (
        <BulbTwoTone
          twoToneColor={isOnline === true ? "green" : "red"}
          style={{ fontSize: "20px" }}
        />
      ),
    },
    {
      title: t("Active"),
      key: "isActive",
      dataIndex: "isActive",
      render: (isActivated) => (
        <BulbTwoTone
          twoToneColor={isActivated === true ? "green" : "red"}
          style={{ fontSize: "20px" }}
        />
      ),
    },
    {
      title: t("UpdatedTime"),
      dataIndex: "updated_time",
      key: "updated_time",
      render: (time) => {
        if (time) {
          let unix_timestamp = time;
          let date = new Date(unix_timestamp * 1000);
          let hours = date.getHours();
          let minutes = "0" + date.getMinutes();
          let seconds = "0" + date.getSeconds();
          let formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);

          return `${date.toLocaleDateString("en-US")} ${formattedTime}`;
        } else {
          return '';
        }
      },
    },
    {
      title: t("Action"),
      key: "action",
      dataIndex: "id",
      render: (id, row) =>
        //!auth.isSuperAdmin ? null :
        (
          <Space size="middle">
            <Button onClick={showModal} style={{fontSize: '12px'}}>{t("Edit")}</Button>
            <Button danger onClick={handleShowConfirm} style={{fontSize: '12px'}}>
                {t("Delete")}
            </Button>
          </Space>
        ),
    },
  ];

  const flatColumns = [
    {
      dataIndex: "id",
      key: "checkbox",
      title: (
        <Checkbox onClick={() => changeSelectAll()} checked={areAllChecked} />
      ),
      render: (id, row) => {
        return (
          <Checkbox
            value={id}
            checked={checkedIDs.includes(id)}
            onClick={() => {
              changeCheckedValue(row, id);
              setSelectedFlat(id);
            }}
          />
        );
      },
    },
    {
      title: t("FlatNumber"),
      dataIndex: "flat_number",
      key: "flat_number",
    },
    {
      title: `${t("Admin")}`,
      dataIndex: "admin",
      key: "admin",
      render: (admin, row) => {
        let user = 'NONE';
        (flatsUser || []).filter((item) => {
          if(item.flat_id === row.id) {
            if(item.user) {
              user = item.user.name;
            }
          }
        });
        
        return <div>{user}</div>;
      },
    },
    {
      title: t("Package"),
      dataIndex: "active_package_id",
      key: "active_package_id",
      render: (active_package_id) => {
        if (!active_package_id) {
          return "NONE";
        }
        return <div>{`${active_package_id}`}</div>;
      },
    },
    {
      title: t("ActiveUntil"),
      key: "active_until",
      dataIndex: "active_until",
      render: (active_until) => {
        if (!active_until) {
          return "NONE";
        }
        return <div>{`${format(new Date(active_until), "dd-MMM-yy H:m:s")}`}</div>;
      },
    },
    {
      title: t("Users"),
      key: "id",
      dataIndex: "id",
      render: (id) => {
        let count = 0;
        (flatsUser || []).filter((item) => {
          if(item.flat_id === id) {
            count = count + 1;
          }
        });

        return <div onDoubleClick={() => handleAddUserCodeModal(id, count)}>{`${count || 0}`}</div>;
      },
    },
    {
      title: t("Balance"),
      key: "balance",
      dataIndex: "balance",
      render: (balance) => {
        return <div>{`${+balance}`}</div>;
      },
    },
    // {
    //   title: t("NFC"),
    //   key: "nfc",
    //   dataIndex: "id",
    //   render: (id, row) => {
    //     return (
    //       <Space size="middle">
    //         <Switch
    //             checked={active}
    //             onClick={() => handleNFCActivationOnClick(id)}
    //         />
    //       </Space>
    //     );
    //   },
    // },
    //  {
    //   title: t("Color"),
    //   key: "color",
    //   dataIndex: "color",
    //   render: (color, row) => {
    //     return (
    //       <Select
    //         style={{ width: 100, margin: "0 8px 10px 0" }}
    //         placeholder="Select"
    //         value={!color ? colors[0] : color}
    //         onChange={(value) => onFlatColorChange(value, row.flatNumber)}
    //         options={colors}
    //         dropdownMatchSelectWidth={false}
    //       />
    //     );
    //   },
    //   filters: [
    //     {
    //       text: 'Red',
    //       value: '#FF0000',
    //     },
    //     {
    //       text: 'Green',
    //       value: '#008000',
    //     },
    //     {
    //       text: 'Orange',
    //       value: '#FFA500',
    //     },
    //   ],
    //   onFilter: (value: string, record) => { 
    //     if(record.color) {
    //       return record.color.indexOf(value) === 0
    //     }
    //   },
    // },
    {
      title: t("Action"),
      key: "flatDetails",
      dataIndex: "id",
      render: (id, row) => {
        return (
          <Space size="middle">
            <Link
              to={{ pathname: `/elevators/${elevatorDetails[0].id}/flat/${row.flat_number}`, state: { flat: row, flatsUser: flatsUser } }}
            >
              <EditTwoTone onClick={() => handleDetailsOnClick(row)} />
            </Link>
            <DeleteTwoTone
              twoToneColor="red"
              onClick={() => handleDeleteFlatConfirm(row.id)}
            />
          </Space>
        );
      },
    },
  ];

  const elevatorsColumns = [
    {
      dataIndex: "id",
      key: "checkbox",
      title: (
        <Checkbox onClick={() => changeSelectAll()} checked={areAllChecked} />
      ),
      render: (id, row) => {
        return (
          <Checkbox
            value={id}
            checked={checkedIDs.includes(id)}
            onClick={() => {
              toggleElevatorConnect(row, id);
            }}
          />
        );
      },
    },
    {
      title: t("ID"),
      dataIndex: "id",
      key: "id",
    },
    {
      title: t("Address"),
      key: "address",
      dataIndex: "address",
      render: (address) => {
        if (!address) {
          return "Not Activated";
        }
        return <div>{`${address.address ? address.address : address}`}</div>;
      }
    }
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const history = useHistory();
  const location = useLocation();
  const handleTableChange = (pagination: any) => {
    setLimit(pagination.pageSize);
    history.push(`?page=${pagination.current}&limit=${pagination.pageSize}`); // update the URL
  };
  useEffect(() => {
    // Read the current page number from the URL query parameters
    const query = qs.parse(location.search, { ignoreQueryPrefix: true });
    const page = typeof query.page === 'string' ? parseInt(query.page as string) : 1;
    const limit = typeof query.limit === 'string' ? parseInt(query.limit as string) : 10;
    setCurrentPage(page);
    setLimit(limit);
  }, [location.search]);

  return (
    <div>
      <Button
        style={{ marginRight: 8 }}
      >
        <Link to={{ pathname: `${elevatorId.id}/history` }}>{" "}{t("History")}{" "}</Link>
      </Button>
      <Button onClick={handleAddFlatOnClick} style={{ marginRight: 8 }}>
        {" "}{t("AddFlat")}{" "}
      </Button>
      {/* <Button onClick={handleAddNFCForFlatOnClick} style={{ marginRight: 8 }}>
        {" "}{t("AddNFCCodes")}{" "}
      </Button> */}
      <Button onClick={handleAddNFCsOnClick} style={{ marginRight: 8 }} disabled={checkedIDs.length !== 1}>
        {" "}{t("UploadNFCCodes")}{" "}
      </Button>
      <Button onClick={handleForceOpen} style={{ marginRight: 8 }}>
        {" "}{t("Open")}{" "}
      </Button>
      <Button onClick={handleAddElevatorOnClick} style={{ marginRight: 8 }}>
        {" "}{t("ConnectElevator")}{" "}
      </Button>
      <Divider orientation={"left"} />
      <Table columns={columns} dataSource={elevatorDetails} pagination={false} />
      <Divider orientation={"left"}>Flats </Divider>
      <Button
        onClick={handleAddNotificationOnClick}
        disabled={checkedIDs.length === 0}
        icon={<SendOutlined />}
        style={{ marginLeft: 16 }}
      >
          {t("SendNotification")}
      </Button>
      <Button
        onClick={() => setIsSortedByBalance(!isSortedByBalance)}
        style={{ marginLeft: 16 }}
      >
        {isSortedByBalance ? t("SortWithFlatNumbers") : t("SortWithBalance")}
      </Button>
      <Divider />
      <Table
        columns={flatColumns}
        dataSource={flats}
      />
      <Modal
        title={t("EditElevator")}
        open={isModalVisible}
        onOk={form.submit}
        onCancel={handleCancelEdit}
        cancelText={t("Cancel")}
        style={{ pointerEvents: "none" }}
        okButtonProps={{ disabled: isEditElevatorLoading }}
        cancelButtonProps={{ disabled: isEditElevatorLoading }}
      >
        <Form
          form={form}
          disabled={isEditElevatorLoading}
          onFinish={handleCloseForm}
          initialValues={{
            elevator_name: elevatorDetails[0]?.name,
            //serial_number: elevatorDetails[0]?.serial_code,
            address: elevatorDetails[0] && elevatorDetails[0].address ? elevatorDetails[0]?.address.address : "",
            entrance: elevatorDetails[0] && elevatorDetails[0].entrance_number ? elevatorDetails[0]?.entrance_number : "",
            permanent_code: pinCode && pinCode['value'] ? pinCode['value'] : "",
            available_codes: pinCode && pinCode['value'] ? pinCode['value'] : ""
          }}
        >
            <Form.Item label={t("ElevatorName")} name="elevator_name">
                <Input type={"text"} placeholder={t("ElevatorNamePlaceholder")}/>
            </Form.Item>
            {/* <Form.Item label={t("ElevatorSerialNumber")} name="serial_number" rules={[{ required: true, message: "This field is required!" }]}>
                <Input type={"text"} placeholder={t("ElevatorSerialNumberPlaceholder")}/>
            </Form.Item> */}
            <Form.Item label={t("ElevatorAddress")} name="address">
                <Input type={"text"} placeholder={t("ElevatorAddressPlaceholder")}/>
            </Form.Item>
            <Form.Item label={t("ElevatorEntrance")} name="entrance">
                <Input type={"text"} placeholder={t("ElevatorEntrancePlaceholder")}/>
            </Form.Item>
            <div style={{display: "flex"}}>
              <Form.Item label={t("PermanentCode")} name="permanent_code">
                  <Input type={"text"} placeholder={t("PermanentCodePlaceholder")}  style={{ width: 245 }}/>
              </Form.Item>
            </div>
            <Form.Item label={t("AvailableCodes")} name="available_codes">
                <Select
                  style={{ width: 250, margin: "0 8px 10px 0" }}
                  placeholder={t("SelectPermanentCodesPlaceholder")}
                  // defaultValue={pinCodes && pinCodes[0] && pinCodes[0].value}
                  onChange={(value, data) => onPinCodeChange(value, data, true)}
                  options={pinCodes}
                  value={selectedPinCodes || []}
                  dropdownMatchSelectWidth={false}
                  mode="multiple"
                />
                <DeleteTwoTone
                  twoToneColor="red"
                  onClick={() => handleDeletePinCodes(selectedPinCodes)}
                />
            </Form.Item>

            {auth.authState.userInfo.id === "53714479-21b0-47c0-9b7d-101001e94ac9" ?
                <Form.Item label={t("GuestPins")} name="guest_pins_admin">
                    <Select
                        style={{ width: 250, margin: "0 8px 10px 0" }}
                        placeholder={t("SelectCodesPlaceholder")}
                        // defaultValue={pinCodes && pinCodes[0] && pinCodes[0].value}
                        onChange={(value, data) => onPinCodeChange(value, data, false)}
                        options={guestPins}
                        value={selectedGuestPinCodes || []}
                        dropdownMatchSelectWidth={false}
                        mode="multiple"
                    />
                    <DeleteTwoTone
                        twoToneColor="red"
                        onClick={() => handleDeletePinCodes(selectedGuestPinCodes)}
                    />
                </Form.Item> :
                <div>
                    { guestPins.length > 0 && <Form.Item style={{ paddingTop: 0 }} label={t("GuestPins")} name="guest_pins">
                        <div>{guestPins.map(pin => pin.value).join(', ')}</div>
                    </Form.Item> }
                </div>
            }
        </Form>
      </Modal>
      <Modal
        title={t("AddFlat")}
        open={showAddFlats}
        onOk={form.submit}
        onCancel={handleAddFlatCancel}
        style={{ pointerEvents: "none" }}
        footer={null}
      >
        <Form form={form} onFinish={addFlat}>
            <Form.Item label={t("FlatNumber")} name="firstFlat" rules={[{ required: true, message: "This field is required!" }]}>
                <Input type={"text"} placeholder={t("From")}/>
            </Form.Item>
            <Form.Item name="lastFlat" style={{display: "flex", justifyContent: "flex-end"}}>
                <Input type={"text"} placeholder={t("To")} style={{ width: 370 }}/>
            </Form.Item>
          <Button type="primary" htmlType="submit" disabled={isLoading} loading={isLoading}>
              {t("Save")}
          </Button>
        </Form>
      </Modal>
      <Modal
        title={t("AddUserCode")}
        open={showAddUserCodeModal}
        onCancel={handleCancelAddUserCode}
        style={{ pointerEvents: "none" }}
        footer={null}
      >
        <Form form={form} onFinish={addUserCode}>
          <Form.Item
            label={t("User Number")}
            name="number"
            preserve
          >
            <Input
              type="text"
            />
          </Form.Item>
          <Button type="primary" htmlType="submit">
            {t("Add")}
          </Button>
        </Form>
      </Modal>
      <Modal
        title={t("AddNFCCodes")}
        open={addNFCModal}
        onOk={form.submit}
        onCancel={handleAddNFCModalCancel}
        cancelText={t("Cancel")}
        style={{ pointerEvents: "none" }}
        //okButtonProps={{ disabled: isEditElevatorLoading }}
        //cancelButtonProps={{ disabled: isEditElevatorLoading }}
      >
        <Form
          form={form}
          //disabled={isEditElevatorLoading}
          onFinish={handleAddNFCForFlat}
        >
            <Form.Item label={t("FlatID")} name="flat_id" rules={[{ required: true, message: "This field is required!" }]}>
                <Input type={"text"} placeholder={t("FlatIDPlaceholder")}/>
            </Form.Item>
            <Form.Item label={t("NFCCode")} name="nfc_code" rules={[{ required: true, message: "This field is required!" }]}>
                <Input type={"text"} placeholder={t("NFCCodePlaceholder")}/>
            </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Upload NFC Codes"
        open={NFCUploadModal}
        onCancel={handleAddNFCsCancel}
        cancelButtonProps={{ style: { display: "none" } }}
        footer={null}
      >
        <p>
            <input
            type={"file"}
            id={"csvFileInput"}
            accept={".csv"}
            onChange={handleOnChange}
            />
        </p>
        <p>
            <Button type="primary" onClick={uploadNFCFile}>{t("Upload")}</Button>
        </p>
      </Modal>
      <Modal
        title={t("AddNotification")}
        open={showNotificationModal}
        onOk={form.submit}
        onCancel={handleAddNotificationCancel}
        style={{ pointerEvents: "none" }}
        footer={null}
      >
        <Form form={form} onFinish={addNotifications}>
          <Form.Item
            label={t("Title")}
            name="title"
            rules={[{ required: true, message: "Please input the title!" }]}
            preserve
          >
            <Input type={"text"} />
          </Form.Item>
          <Form.Item
            label={t("Message")}
            name="body"
            rules={[{ required: true, message: "Please input the message!" }]}
            preserve
          >
            <Input.TextArea />
          </Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={isNotificationSending}
            icon={<SendOutlined />}
          >
              {t("Send")}
          </Button>
        </Form>
      </Modal>
       {/* <Modal
        title={t("OpenElevator")}
        open={forceOpenModal}
        onOk={form.submit}
        onCancel={handleOpenCancel}
        cancelText={t("Cancel")}
        style={{ pointerEvents: "none" }}
        //okButtonProps={{ disabled: isEditElevatorLoading }}
        //cancelButtonProps={{ disabled: isEditElevatorLoading }}
      >
        <Form
          form={form}
          onFinish={handleForceOpen}
        >
            <Form.Item label={t("Code")} name="code" rules={[{ required: true, message: "This field is required!" }]}>
                <Input type={"text"} placeholder={t("EnterCode")}/>
            </Form.Item>
        </Form>
      </Modal> */}
      <Modal
        title={t("ConnectElevator")}
        open={showConnectElevators}
        onOk={form.submit}
        onCancel={handleAddElevatorsCancel}
        style={{ pointerEvents: "none" }}
        footer={null}
      >
        <Form form={form} onFinish={() => connectElevators(checkedIDs)}>
          <TableSearchInput
            setFilterValue={setFilteredValue}
            placeHolder={t("SearchForAddress")}
          />
          <Table
            columns={elevatorsColumns}
            dataSource={elevatorsData.filter((el) =>
              el.address?.address
                ?.toLowerCase()
                .includes(filteredValue.toLowerCase())
            )}
            pagination={false}
          />
          <Button type="primary" htmlType="submit" style={{ marginTop: "1em" }}>
            {t("Save")}
          </Button>
        </Form>
      </Modal>
    </div>
  );
};
